<script setup lang="ts">
// types
import type { Nullable } from '@revolutionprep/types'

// vuetify
import { useTheme } from 'vuetify'

/**
 * runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * vuetify
 * ==================================================================
 */
const vuetifyTheme = useTheme()

/**
 * state
 * ==================================================================
 */
const capturedError = ref<Nullable<Error>>(null)
const renderError = ref(false)

/**
 * stores
 * ==================================================================
 */
const organizationStore = useOrganizationStore()
const { organizationInfo: company } = storeToRefs(organizationStore)

/**
 * composables
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()

/**
 * computed
 * ==================================================================
 */
const actor = computed(() => {
  return $actor.core.actor.value
})

const isLoggedIn = computed(() => {
  return Boolean($actor.core.isLoggedIn.value && actor.value)
})

/**
 * lifecycle hooks
 * ==================================================================
 */
onErrorCaptured((
  err: Error,
  _instance: ComponentPublicInstance | null,
  info: string
) => {
  doHandleError(err)
  capturedError.value = err
  renderError.value = info === 'render'
  return false
})
</script>

<template>
  <v-app>
    <v-main
      :style="{ background: vuetifyTheme.current.value.colors.backgroundgrey }"
    >
      <v-container
        class="container mx-auto pt-5"
        :fill-height="!isLoggedIn"
      >
        <slot v-if="!renderError" />
        <r-error-display
          v-else-if="renderError && capturedError"
          :error="capturedError"
        />
      </v-container>
    </v-main>
    <client-only>
      <r-footer
        :company="company"
        :logged-in="isLoggedIn"
        :settings="config"
        class="footer footerHide"
      />
    </client-only>
  </v-app>
</template>

<style scoped>
.container {
  height: 100%;
  width: 80vw;
}

.footer {
  max-height: 4.8rem;
}

@media (max-height: 560px) {
  .footerHide {
    display: none;
  }
}

@media (max-width: 600px) and (max-height: 720px) {
  .footerHide {
    display: none;
  }
}
</style>
